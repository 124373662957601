import React, { useCallback, useEffect } from 'react'

import { FallbackProps } from 'react-error-boundary'
import useBoundState from 'store'
import BridgeService from 'common/services/bridgeService'
import { WouterService } from 'common/services/wouterService'
import { useLocalErrorHandler } from 'common/hooks'

import { Button } from '@interco/inter-ui/components/Button'
import { Signal } from '@interco/inter-ui/components/Signal'
import { AppBar } from '@interco/inter-ui/components/AppBar'
import Canceled from '@interco/icons/orangeds/MD/canceled'
import Home from '@interco/icons/orangeds/MD/home'

import { TrackableError } from '../../exceptions'
import { ContainerWithFooter } from '../containerWithFooter'
import * as S from './styles'

const ErrorFallback = ({ error }: FallbackProps) => {
  const setTitle = useBoundState((state) => state.setNavbarTitle)
  const setNavbarHideLeftIcon = useBoundState((state) => state.setNavbarHideLeftIcon)
  const resetNavbar = useBoundState((state) => state.resetNavbar)
  const { headerTitle } = useBoundState((state) => state.viewConfig)

  const { handlerError } = useLocalErrorHandler()

  const { isBSView } = useBoundState((state) => state.queryParams)

  const titleError = headerTitle || ''

  const frontendLogRef = `${Date.now()}`
  const trackableError: TrackableError = error
  trackableError.errorLogRef = frontendLogRef

  const goToHome = async () => {
    try {
      await BridgeService.navigateToHome(WouterService.navigate)
    } catch (err) {
      handlerError(err as Error, 'home.NavBarRightIcon.goToHome')
    }
  }

  const goToInsurance = useCallback(async () => {
    try {
      isBSView
        ? await BridgeService.openDeeplinkBS('bancointer://seguros')
        : await BridgeService.openDeepLink('bancointer://seguros')
    } catch (err) {
      handlerError(err as Error, 'Ineligibility.goToInsurance.BridgeService.openDeepLink')
    }
  }, [handlerError])

  const footer = (
    <Button fullWidth style={{ margin: '4px' }} onClick={goToInsurance}>
      Ir para a home de seguros
    </Button>
  )

  useEffect(() => {
    resetNavbar()
    setNavbarHideLeftIcon(true)
    setTitle(titleError)
  }, [resetNavbar, setNavbarHideLeftIcon, setTitle])

  return (
    <ContainerWithFooter stickyFooter={footer}>
      <AppBar
        hideLeftIcon
        rightIcon={
          <Home
            data-testid="app-bar-test"
            color="var(--primary500)"
            width={24}
            height={24}
            onClick={goToHome}
          />
        }
      >
        {titleError}
      </AppBar>

      <S.Content>
        <S.SignalContent>
          <Signal icon={Canceled} theme="error" intensity="dark" />
        </S.SignalContent>
        <S.ErrorTitle variant="headline-h1">Falha no carregamento</S.ErrorTitle>
        <S.ErrorMessage variant="body-3">
          Infelizmente não foi possível carregar a condição. Tente novamente e confira o que
          preparamos para você.
        </S.ErrorMessage>
      </S.Content>
    </ContainerWithFooter>
  )
}

export default ErrorFallback
