export class ServiceError<T> extends Error {
  isPresentable: boolean

  data: T

  headers?: unknown

  status: number

  constructor(isPresentable: boolean, data: T, message: string, status: number, headers?: unknown) {
    super(message)

    this.name = 'ServiceError'
    this.isPresentable = isPresentable
    this.data = data
    this.headers = headers
    this.status = status
  }
}

export interface TrackableError extends Error {
  errorCodeRef?: string

  // string is to prevent new relic interpret this field as a timestamp when populated with Date.now()
  errorLogRef?: string
}

export const GENERAL_SERVICE_ERROR_MESSAGE =
  'Something went wrong in the request, see the data object for more details.'
