import { useCallback } from 'react'

/**
 * A performatic way to inject tag links into the document head
 * @param href
 * @param integrity
 * @param crossOrigin
 * @returns a memoized function responsible to add the link
 */
export const useLink = () =>
  useCallback((href: string, integrity?: string, crossOrigin?: string) => {
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.media = 'none'
    link.href = href

    if (integrity) {
      link.integrity = integrity
    }

    if (crossOrigin) {
      link.crossOrigin = crossOrigin
    }

    link.onload = () => {
      link.media = 'all'
    }

    document.head.appendChild(link)
  }, [])
