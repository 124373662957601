import { StateCreator } from 'zustand'

import { INITIAL_STATE, ProductState } from './types'

const createProductSlice: StateCreator<ProductState, [], [], ProductState> = (set) => ({
  product: INITIAL_STATE,
  setProduct: (product) => set(() => ({ product })),
  resetProduct: () => set(() => ({ product: INITIAL_STATE })),
})

export default createProductSlice
