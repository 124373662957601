import { EnvVariableKeys } from './types'

export class EnvVariableService {
  static getVariable(key: EnvVariableKeys): string {
    const variable = process.env[key]

    if (!variable) {
      throw new Error(`EnviromentVariableMissingError: ${key} is not defined.`)
    }

    return variable
  }

  /**
   * A function to convert any env variable to boolean
   * @param key the key of the variable in the @enum EnvVariableKeys
   * @param assertExpression an value to expect in the variable
   * @returns true only when the env value is equal to
   * the assertExpression or the string 'true'
   */
  static getVariableAsBoolean(key: EnvVariableKeys, assertExpression?: string): boolean {
    const env = EnvVariableService.getVariable(key)
    return env ? env.toLowerCase() === (assertExpression ?? 'true') : false
  }
}
