import { useCallback } from 'react'

import BridgeService from 'common/services/bridgeService'
import useBoundState from 'store'
import { useErrorHandler } from 'react-error-boundary'
import { MonitoringService } from 'common/services/monitoringService'
import { EnvVariableService } from 'common/services/envService'
import { EnvVariableKeys } from 'common/services/envService/types'
import { MockService } from 'common/mockServices/mockService'
import { AppInfoService } from 'common/services/appInfoService'

import { initTheme, Theme } from '@interco/inter-ui'

export const useOnWebviewInit = () => {
  const handleError = useErrorHandler()
  const setUserInfo = useBoundState((state) => state.setUserInfo)
  const setIsDarkMode = useBoundState((state) => state.setIsDarkMode)

  const isRemoteBridgeDebugLogEnable = EnvVariableService.getVariableAsBoolean(
    EnvVariableKeys.REMOTE_BRIDGE_DEBUG_LOG_ENABLED,
  )

  return useCallback(async () => {
    try {
      const response = await BridgeService.getUserInfo()
      setUserInfo(response)
      await MonitoringService.setDefaultAttributes(response.account)
      if (isRemoteBridgeDebugLogEnable) {
        BridgeService.enableRemoteBridgeDebugLog()
      }
      if (!MockService.account) {
        MockService.account = response.account
        MockService.prepareMatchHeadersByUserAccount()
      }
      initTheme(Theme.PF, AppInfoService.isDarkMode)
      setIsDarkMode(AppInfoService.isDarkMode)
    } catch (error) {
      // handler error
      handleError(error)
    }
  }, [handleError, isRemoteBridgeDebugLogEnable, setIsDarkMode, setUserInfo])
}
