import { StateCreator } from 'zustand'

import { INITIAL_STATE, NavbarState } from './types'

const createNavbarSlice: StateCreator<NavbarState, [], [], NavbarState> = (set) => ({
  navbar: INITIAL_STATE,
  setNavbarTitle: (title) => set((state) => ({ navbar: { ...state.navbar, title } })),
  setNavbarHideLeftIcon: (hideLeftIcon) =>
    set((state) => ({ navbar: { ...state.navbar, hideLeftIcon } })),
  setNavbarLeftIcon: (leftIcon) => set((state) => ({ navbar: { ...state.navbar, leftIcon } })),
  setNavbarRightIcon: (rightIcon) => set((state) => ({ navbar: { ...state.navbar, rightIcon } })),
  setHideNavbar: (hideNavbar) => set((state) => ({ navbar: { ...state.navbar, hideNavbar } })),
  resetNavbar: () => set(() => ({ navbar: INITIAL_STATE })),
})

export default createNavbarSlice
