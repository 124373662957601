export type IneligibilityState = {
  ineligibility: IneligibilityObject
} & IneligibilityActions

export interface IneligibilityObject {
  code: number
  pageTitle: string
  icon: string
  title: string
  descriptions: string[]
  footer: {
    primaryButton: string
    secondaryButton: string
  }
}

export interface IneligibilityActions {
  setIneligibility: (ineligibility: IneligibilityObject) => void
  resetIneligibility: () => void
}

export const INITIAL_STATE: IneligibilityObject = {
  code: 0,
  pageTitle: '',
  icon: '',
  title: '',
  descriptions: [],
  footer: {
    primaryButton: '',
    secondaryButton: '',
  },
}
