import { StateCreator } from 'zustand'

import { DarkModeState, INITIAL_STATE } from './types'

const createDarkModeSlice: StateCreator<DarkModeState, [], [], DarkModeState> = (set) => ({
  darkMode: INITIAL_STATE,
  setIsDarkMode: (isDarkMode) => set(() => ({ darkMode: { isDarkMode } })),
})

export default createDarkModeSlice
