export type RouterState = {
  routerState: RouterStateObject
} & RouterStateActions

export interface RouterStateObject {
  routerState: Record<string, unknown>
}

export interface RouterStateActions {
  setRouterState: (routerState: Record<string, unknown>) => void
  resetRouterState: () => void
}

export const INITIAL_STATE: RouterStateObject = {
  routerState: {},
}
