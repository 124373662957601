import React, { useEffect } from 'react'

import Overlay from 'common/components/overlay'
import { MonitoringService } from 'common/services/monitoringService'
import { WouterService } from 'common/services/wouterService'
import { ErrorBoundary } from 'react-error-boundary'
import RoutesComponent from 'routes'
import useBoundState from 'store'
import { useLocation } from 'wouter'
import ErrorFallback from 'common/components/errorFallback'
import { useOnWebviewInit } from 'common/hooks/useOnWebviewInit'
import { useLink } from 'common/hooks'

function App() {
  const addLink = useLink()
  const resetErrorState = useBoundState((state) => state.resetError)
  const setLocation = useLocation()[1]
  const onWebviewInit = useOnWebviewInit()

  WouterService.initWouter(setLocation)

  const resetError = () => {
    resetErrorState()
  }

  useEffect(() => {
    addLink('https://cdn.bancointer.com.br/inter-ui/4.x/latest/inter-ui.css')
    addLink('https://fonts.googleapis.com/css?family=Inter&display=swap')
    addLink('https://fonts.googleapis.com/css?family=Sora&display=swap')

    onWebviewInit()
  }, [])

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={resetError}
      onError={(error, info) =>
        MonitoringService.noticeError(error, {
          componentStackTrace: info.componentStack,
        })
      }
    >
      <Overlay />
      <RoutesComponent />
    </ErrorBoundary>
  )
}

export default App
