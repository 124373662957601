export type TypeCampaignState = {
  type: TypeCampaignObject
} & TypeCampaignActions

export interface TypeCampaignObject {
  typeCampaign?: TypeCampaignEnum
  redirectTo?: string
}

export interface TypeCampaignActions {
  setTypeCampaign: (typeCampaign?: TypeCampaignEnum) => void
  setRedirectTo: (redirectTo?: string) => void
  resetTypeCampaign: () => void
}

export const INITIAL_STATE: TypeCampaignObject = {
  typeCampaign: undefined,
  redirectTo: '',
}

export enum TypeCampaignEnum {
  REDIRECT = 'REDIRECT',
  QUICK_BUY = 'QUICK_BUY',
}
