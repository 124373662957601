export enum EnvVariableKeys {
  INTER_ENV = 'REACT_APP_INTER_ENV',
  API_BASE_URL = 'REACT_APP_API_BASE_URL',
  MOCK_SERVER_BASE_URL = 'REACT_APP_MOCK_SERVER_BASE_URL',
  LOCAL_LOG_ENABLED = 'REACT_APP_LOCAL_LOG_ENABLED',
  REMOTE_LOG_ENABLED = 'REACT_APP_REMOTE_LOG_ENABLED',
  REMOTE_BRIDGE_DEBUG_LOG_ENABLED = 'REACT_APP_REMOTE_BRIDGE_DEBUG_LOG_ENABLED',
  APP_API_CAMPAIGN = 'REACT_APP_API_CAMPAIGN',
  APP_API_BRIDGE_CAMPAIGN = 'REACT_APP_API_BRIDGE_CAMPAIGN',
  APP_API_SUBMIT = 'REACT_APP_API_SUBMIT',
  APP_API_BRIDGE_SUBMIT = 'REACT_APP_API_BRIDGE_SUBMIT',
}
