import { EnvVariableService } from 'common/services/envService'
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  logISafeRequest,
  logISafeResponse,
  logUserInfoResponse,
} from 'common/loggers/bridgeServiceLogger'

import InterWebviewBridge, {
  interWbAuth,
  interWbNavigate,
  interWbSession,
  IWbISafeResponse,
  IWbUserInfo,
  WbEnvironments,
  WbSessionActions,
  IWbTic,
  IWbTac,
} from '@interco/inter-webview-bridge'

import AppInfoMockService from '../../mockServices/appInfoMockService'
import ISafeTokenMockService from '../../mockServices/iSafeTokenMockService'
import { MockService } from '../../mockServices/mockService'
import { MonitoringService } from '../monitoringService'
import UserInfoMockService from '../userInfoMockService'
import { InfoBridge, IRequestISafe, IWbAppInfo } from './types'
import { EnvVariableKeys } from '../envService/types'

const IS_NOT_RUNNING_PROD = !EnvVariableService.getVariableAsBoolean(
  EnvVariableKeys.INTER_ENV,
  'production',
)

class BridgeService {
  private static interWb = InterWebviewBridge.getInstance()

  private static handleBackButton: (() => void) | undefined

  private static addUrlProtocol(url: string) {
    const HTTPS_PROTOCOL = 'https://'
    const HTTP_PROTOCOL = 'http://'
    if (url.startsWith(HTTPS_PROTOCOL) || url.startsWith(HTTP_PROTOCOL)) {
      return url
    }
    return HTTPS_PROTOCOL + url
  }

  static getEnvironment(): WbEnvironments {
    return this.interWb.getEnvironment()
  }

  static isBrowser(): boolean {
    return this.interWb.getEnvironment() === WbEnvironments.BROWSER
  }

  static enableRemoteBridgeDebugLog(): void {
    this.interWb.setDebug(true)
    ;(this.interWb as unknown as InfoBridge).info = (
      action: string,
      evitId: string,
      message: string,
    ) => {
      // eslint-disable-next-line no-console
      console?.info(`InterWebviewBridge - ${action}(${evitId}): ${message}`)

      MonitoringService.log(
        'BridgeService.enableRemoteBridgeDebugLog',
        `InterWebviewBridge - ${action}(${evitId}): ${message}`,
      )
    }
  }

  static async getUserInfo(): Promise<IWbUserInfo> {
    let userInfo

    if (BridgeService.isBrowser() && IS_NOT_RUNNING_PROD) {
      userInfo = await UserInfoMockService.requestUserInfo(MockService.account)
    } else {
      userInfo = await interWbSession.getUserInfo()
    }
    logUserInfoResponse(userInfo)
    return userInfo
  }

  static async getAppInfo(): Promise<IWbAppInfo> {
    let appInfo

    if (BridgeService.isBrowser() && IS_NOT_RUNNING_PROD) {
      appInfo = await AppInfoMockService.requestAppInfo()
    } else {
      appInfo = await interWbSession.getAppInfo()
    }
    return appInfo
  }

  static async getISafeToken(requestData: IRequestISafe): Promise<IWbISafeResponse> {
    logISafeRequest(requestData)

    let iSafeToken
    if (BridgeService.isBrowser() && IS_NOT_RUNNING_PROD) {
      iSafeToken = await ISafeTokenMockService.requestISafe(
        requestData.category,
        requestData.checkType,
        requestData.feature,
        requestData.value,
      )
    } else {
      iSafeToken = await interWbAuth.requestISafe(
        requestData.category,
        requestData.checkType,
        requestData.feature,
        requestData.value,
      )
    }

    logISafeResponse(iSafeToken)

    return iSafeToken
  }

  static async goToHome(): Promise<void> {
    if (!this.isBrowser()) {
      await interWbNavigate.requestGoBack()
    }
  }

  static requestGoBack() {
    if (!this.isBrowser()) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      interWbNavigate.requestGoBack()
    }
  }

  static async openDeepLink(url: string): Promise<void> {
    await interWbNavigate.openDeepLink(url)
  }

  static async sharedContentPDF(url: string, title: string): Promise<void> {
    if (this.isBrowser()) {
      window.open(BridgeService.addUrlProtocol(url), '_blank')?.focus()
      return
    }
    try {
      await this.interWb.execute({
        action: 'sharedContentPDF',
        metadata: {
          url,
          title,
        },
      })
    } catch (e) {
      MonitoringService.noticeError(e as Error, {
        errorCodeRef: 'BridgeService.sharedContentPDF.sharedContentPDF',
      })
    }
  }

  static async sharedContentEmail(
    subject: string,
    recipient: string,
    htmlBody: string,
  ): Promise<void> {
    if (this.isBrowser()) {
      const urlMailto = `mailto:${recipient}&subject=${subject}&body=${htmlBody}`
      window.open(urlMailto, '_blank')?.focus()
      return
    }

    try {
      await this.interWb.execute({
        action: 'sharedContentEmail',
        metadata: {
          subject,
          recipient,
          htmlBody,
        },
      })
    } catch (e) {
      MonitoringService.noticeError(e as Error, {
        errorCodeRef: 'BridgeService.sharedContentEmail.sharedContentEmail',
      })
    }
  }

  static async openBrowser(url: string): Promise<void> {
    if (this.isBrowser()) {
      window.open(BridgeService.addUrlProtocol(url), '_blank')?.focus()
      return
    }

    try {
      await this.interWb.execute({
        action: 'openBrowser',
        metadata: {
          url,
        },
      })
    } catch (e) {
      MonitoringService.noticeError(e as Error, {
        errorCodeRef: 'BridgeService.openBrowserRequest.openBrowser',
      })
    }
  }

  static async goToAppHomeOrgoToHome(navigate: (pathname: string) => void): Promise<void> {
    if (this.isBrowser()) {
      navigate('/')
      return
    }

    if (this.interWb.getEnvironment() === WbEnvironments.ANDROID) {
      try {
        await this.interWb.execute({ action: 'navigateToHome' })
      } catch (e) {
        MonitoringService.noticeError(e as Error, {
          errorCodeRef: 'BridgeService.goToAppHomeOrgoToHome.navigateToHome',
        })
        await BridgeService.goToHome()
      }
    }

    if (this.interWb.getEnvironment() === WbEnvironments.IOS) {
      try {
        await this.interWb.execute({ action: 'navigateHome' })
      } catch (e) {
        MonitoringService.noticeError(e as Error, {
          errorCodeRef: 'BridgeService.goToAppHomeOrgoToHome.navigateHome',
        })
        await BridgeService.goToHome()
      }
    }
  }

  static requestAnalytics(
    name: string,
    params?: Record<string, string>,
    errorCodeRef?: string,
  ): void {
    if (!this.isBrowser()) {
      interWbSession.requestAnalytics(name, params).catch((e) => {
        MonitoringService.noticeError(e, {
          error: errorCodeRef ?? 'no code',
          requestAnalyticsName: name,
          requestAnalyticsParams: params ? JSON.stringify(params) : 'No params',
        })
      })
    }
  }

  static addBackButtonEvent(callback: () => void): void {
    if (!BridgeService.isBrowser()) {
      const iWb = InterWebviewBridge.getInstance()

      if (BridgeService.handleBackButton) {
        iWb.removeWebviewEventListener(WbSessionActions.GO_BACK, BridgeService.handleBackButton)
      }

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      iWb.addWebviewEventListener(WbSessionActions.GO_BACK, callback)

      BridgeService.handleBackButton = callback
    }
  }

  static async callPhoneNumber(phoneNumber: string): Promise<void> {
    await interWbNavigate.callPhoneNumber(phoneNumber)
  }

  static async openPdf(url: string, title: string): Promise<void> {
    if (this.isBrowser()) {
      window.open(BridgeService.addUrlProtocol(url), '_blank')?.focus()
      return
    }
    try {
      await interWbNavigate.openPdf(title, url)
    } catch (e) {
      MonitoringService.noticeError(e as Error, {
        errorCodeRef: 'BridgeService.openPdf.displayPdf',
      })
    }
  }

  static async closeModal(): Promise<void> {
    try {
      await this.interWb.execute({
        action: 'closeModal',
      })
    } catch (e) {
      MonitoringService.noticeError(e as Error, {
        errorCodeRef: 'BridgeService.closeModal',
      })
    }
  }

  static async shareReceipt(url: string): Promise<void> {
    try {
      await this.interWb.execute({
        action: 'shareReceipt',
        metadata: { url },
      })
    } catch (e) {
      MonitoringService.noticeError(e as Error, {
        errorCodeRef: 'BridgeService.shareReceipt',
      })
    }
  }

  static async ticWb(ticPorps: IWbTic): Promise<void> {
    if (!this.isBrowser()) {
      await interWbSession.tic(ticPorps)
    }
  }

  static async tacWb(tacPorps: IWbTac): Promise<void> {
    if (!this.isBrowser()) {
      await interWbSession.tac(tacPorps)
    }
  }

  static async openDeeplinkBS(url: string): Promise<void> {
    if (this.interWb.getEnvironment() === WbEnvironments.ANDROID) {
      try {
        await interWbNavigate.openDeepLink(url)
      } catch (e) {
        MonitoringService.noticeError(e as Error, {
          errorCodeRef: 'BridgeService.openDeeplinkBS.openDeepLink',
        })
        await BridgeService.goToHome()
      }
    }

    if (this.interWb.getEnvironment() === WbEnvironments.IOS) {
      try {
        await this.interWb.execute({ action: 'openDeeplinkBS', metadata: { url } })
      } catch (e) {
        MonitoringService.noticeError(e as Error, {
          errorCodeRef: 'BridgeService.openDeeplinkBS.openDeeplinkBS',
        })
        await BridgeService.goToHome()
      }
    }
  }

  static async navigateToHome(navigate: (pathname: string) => void): Promise<void> {
    if (this.isBrowser()) {
      navigate('/')
      return
    }

    try {
      await this.interWb.execute({ action: 'navigateToHome' })
    } catch (e) {
      MonitoringService.noticeError(e as Error, {
        errorCodeRef: 'BridgeService.goToAppHomeOrgoToHome.navigateToHome',
      })
    }
  }
}

export default BridgeService
