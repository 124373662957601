import { StateCreator } from 'zustand'

import { INITIAL_STATE, QueryParamsState } from './type'

const createQueryParamsSlice: StateCreator<QueryParamsState, [], [], QueryParamsState> = (set) => ({
  queryParams: INITIAL_STATE,
  setIsBSView: (isBSView) => set((state) => ({ queryParams: { ...state.queryParams, isBSView } })),
  setCampaignId: (campaignId) =>
    set((state) => ({ queryParams: { ...state.queryParams, campaignId } })),
  setParams: (params) => set((state) => ({ queryParams: { ...state.queryParams, params } })),
  resetQueryParams: () => set(() => ({ queryParams: INITIAL_STATE })),
})

export default createQueryParamsSlice
