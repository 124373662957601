import BridgeService from '../bridgeService'

export class AppInfoService {
  static DEVICE_NAME_KEY = 'deviceName'

  static SUPER_APP_VERSION_KEY = 'superAppVersion'

  static IS_DARK_MODE_KEY = 'isDarkMode'

  private static _superAppVersion: string

  private static _deviceName: string

  private static _isDarkMode: boolean

  static async appInfo(errorCallback: (error: Error) => void): Promise<void> {
    try {
      const response = await BridgeService.getAppInfo()
      AppInfoService._deviceName = response.nomeAparelho ?? response.deviceName ?? 'unknown'
      AppInfoService._superAppVersion = response.versao ?? response.version ?? 'unknown'
      AppInfoService._isDarkMode = response.isDarkMode ?? false
    } catch (error: unknown) {
      errorCallback(error as Error)
    }
  }

  static get superAppVersion(): string {
    return AppInfoService._superAppVersion
  }

  static get deviceName(): string {
    return AppInfoService._deviceName
  }

  static get isDarkMode(): boolean {
    return AppInfoService._isDarkMode
  }

  /*
   * We had a 3 sections app version that we wanna to convert into a number,
   * example: 10.1.2 -> 100102
   * So the idea is separate this sections in subsections and put them in right
   * decimal position,
   * example:
   * 10.0.0 -> first section is 10 so we have to put 0000 on the right to respect
   * the original position
   * 10.1.1 -> first section is 100000 , second section is 100 and third section
   * is 1. The sum result in 100101
   * 10.11.11 -> fist section is 100000, second section is 1100 and third section
   * is 11. The sum result in 101111
   */
  static convertToNumber(appVersion: string): number {
    const applicationVersion = parseFloat(appVersion).toString().split('.')

    let multiplyFactor = 10000
    let result = 0

    applicationVersion.forEach((section) => {
      const sectionValue = parseInt(section, 10) * multiplyFactor
      multiplyFactor /= 100
      result += sectionValue
    })

    return result
  }
}
