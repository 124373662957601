export type DarkModeState = {
  darkMode: DarkModeObject
} & DarkModeActions

export interface DarkModeObject {
  isDarkMode: boolean
}

export interface DarkModeActions {
  setIsDarkMode: (isDarkMode: boolean) => void
}

export const INITIAL_STATE: DarkModeObject = {
  isDarkMode: false,
}
