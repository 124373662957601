import { StateCreator } from 'zustand'

import { INITIAL_STATE, RouterState } from './type'

const createRouterStateSlice: StateCreator<RouterState, [], [], RouterState> = (set) => ({
  routerState: INITIAL_STATE,
  setRouterState: (routerState) => set(() => ({ routerState: { routerState } })),
  resetRouterState: () => set(() => ({ routerState: INITIAL_STATE })),
})

export default createRouterStateSlice
