import { StateCreator } from 'zustand'

import { INITIAL_STATE, TypeCampaignState } from './type'

const createTypeCampaign: StateCreator<TypeCampaignState, [], [], TypeCampaignState> = (set) => ({
  type: INITIAL_STATE,
  setTypeCampaign: (typeCampaign) => set((state) => ({ type: { ...state.type, typeCampaign } })),
  setRedirectTo: (redirectTo) => set((state) => ({ type: { ...state.type, redirectTo } })),
  resetTypeCampaign: () => set(() => ({ type: INITIAL_STATE })),
})

export default createTypeCampaign
