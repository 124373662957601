import { StateCreator } from 'zustand'

import { BottomSheetState, INITIAL_STATE } from './types'

const createBottomsheetSlice: StateCreator<BottomSheetState, [], [], BottomSheetState> = (set) => ({
  bottomSheet: INITIAL_STATE,
  setBottomSheetChildren: (children: React.ReactNode) =>
    set((state) => ({ bottomSheet: { ...state.bottomSheet, children } })),
  setBottomSheetNoSwipe: (noSwipe: boolean) =>
    set((state) => ({ bottomSheet: { ...state.bottomSheet, noSwipe } })),
  setBottomSheetVisible: (visible: boolean) =>
    set((state) => ({ bottomSheet: { ...state.bottomSheet, bottomSheetVisible: visible } })),
  setBottomSheetFixedHeight: (fixedHeight: string) =>
    set((state) => ({ bottomSheet: { ...state.bottomSheet, fixedHeight } })),
  resetBottomSheet: () => set({ bottomSheet: INITIAL_STATE }),
})

export default createBottomsheetSlice
