import { StateCreator } from 'zustand'

import { INITIAL_STATE, IneligibilityState } from './types'

const createIneligibilitySlice: StateCreator<IneligibilityState, [], [], IneligibilityState> = (
  set,
) => ({
  ineligibility: INITIAL_STATE,
  setIneligibility: (ineligibility) => set(() => ({ ineligibility })),
  resetIneligibility: () => set(() => ({ ineligibility: INITIAL_STATE })),
})

export default createIneligibilitySlice
