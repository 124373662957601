import { StateCreator } from 'zustand'

import { INITIAL_STATE, PaymentState } from './types'

const createPaymentSlice: StateCreator<PaymentState, [], [], PaymentState> = (set) => ({
  payment: INITIAL_STATE,
  setChosenPayment: (chosenPayment) =>
    set(() => ({
      payment: { chosenPayment },
    })),
  resetPayment: () => set(() => ({ payment: INITIAL_STATE })),
})

export default createPaymentSlice
