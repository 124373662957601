import styled from 'styled-components'

export const Container = styled.div`
  background-color: var(--neutral-theme);
  display: flex;
  flex-direction: column;
  position: relative;
  /* min-height: 100vh; */
`

export const Content = styled.div`
  &::-webkit-scrollbar {
    height: 0 !important;
  }
`

export const StickyFooter = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--neutral-theme);
  padding: 0 24px 24px;
`
