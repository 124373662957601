export type QueryParamsState = {
  queryParams: QueryParamsObject
} & QueryParamsActions

export interface QueryParamsObject {
  campaignId: string
  isBSView: boolean
  params: string
}

export interface QueryParamsActions {
  setIsBSView: (isBSView: boolean) => void
  setCampaignId: (campaignId: string) => void
  setParams: (params: string) => void
  resetQueryParams: () => void
}

export const INITIAL_STATE: QueryParamsObject = {
  campaignId: '',
  isBSView: false,
  params: '',
}
