import { useCallback } from 'react'

import { useErrorHandler } from 'react-error-boundary'
import useBoundState from 'store'
import { ErrorResponse } from 'store/error/types'

import { ServiceError, TrackableError } from '../../exceptions'
// [TODO]: Validate if is calling a monitoring service and new realic
export const useLocalErrorHandler = () => {
  const errorHandler = useErrorHandler()

  const withErrorCodeRef = (originalError: unknown, errorCodeRef: string): TrackableError => {
    let trackableError: TrackableError = new Error(JSON.stringify(originalError))
    trackableError.errorCodeRef = errorCodeRef

    if (originalError instanceof Error) {
      trackableError = originalError
      trackableError.errorCodeRef = errorCodeRef
      return trackableError
    }

    if (typeof originalError === 'string') {
      trackableError = new Error(originalError)
      trackableError.errorCodeRef = errorCodeRef
      return trackableError
    }

    return trackableError
  }

  function isDetailedError(errorResponse: ServiceError<ErrorResponse>): boolean {
    if (
      errorResponse?.data?.errors &&
      errorResponse.isPresentable &&
      errorResponse.data.errors[0].code &&
      errorResponse.data.errors[0].message
    ) {
      return true
    }
    return false
  }

  const handlerError = useCallback(
    (error: Error, errorCodeRef: string) => {
      const handlingError = () => {
        const errorInstance = withErrorCodeRef(error, errorCodeRef)
        useBoundState.getState().setIsLoading(false)
        useBoundState.getState().resetError()
        if (error instanceof ServiceError) {
          const isDetailed = isDetailedError(error)
          useBoundState.getState().setIsDetailedError(isDetailed)
          if (isDetailed) {
            useBoundState.getState().setErrorResponse(error.data)
          }
          useBoundState.getState().setErrorInstance(errorInstance)
        }
        useBoundState.getState().setHasError(true)
        errorHandler(errorInstance)
      }
      handlingError()
    },
    [errorHandler],
  )

  return { handlerError }
}
