import { StateCreator } from 'zustand'

import { INITIAL_STATE, UserInfoState } from './types'

const createUserInfoSlice: StateCreator<UserInfoState, [], [], UserInfoState> = (set) => ({
  user: INITIAL_STATE,
  setUserInfo: (info) => set((state) => ({ user: { ...state.user, info } })),
  setBalanceInfo: (balanceInfo) => set((state) => ({ user: { ...state.user, balanceInfo } })),
  resetUser: () => set(() => ({ user: INITIAL_STATE })),
})

export default createUserInfoSlice
