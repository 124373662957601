import { StateCreator } from 'zustand'

import { INITIAL_STATE, ViewConfigState } from './types'

const createViewConfigSlice: StateCreator<ViewConfigState, [], [], ViewConfigState> = (set) => ({
  viewConfig: INITIAL_STATE,
  setViewConfig: (viewConfig) => set(() => ({ viewConfig })),
  resetViewConfig: () => set(() => ({ viewConfig: INITIAL_STATE })),
})

export default createViewConfigSlice
