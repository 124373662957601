export type ImageBannerState = {
  imageBanner: ImageBanner
} & ImageBannerActions

export interface ImageBanner {
  imageBannerUrl?: string
}

export interface ImageBannerActions {
  setImageBanner: (bannerUrl?: string) => void
  resetImageBanner: () => void
}

export const INITIAL_STATE: ImageBanner = {
  imageBannerUrl: '',
}
