import React from 'react'

import { Route, Switch } from 'wouter'
import Shimmer from 'common/components/shimmer'

import TransitionManager from '../common/components/transitionManager'
import { RoutesInfo } from './types'

const HomePage = React.lazy(() => import('../pages/home'))
const ReceiptPage = React.lazy(() => import('../pages/receipt'))
const Ineligibility = React.lazy(() => import('../pages/ineligibility'))
const BottomSheet = React.lazy(() => import('../common/components/bottomSheet'))

export const MainRoutesInfo: Record<string, RoutesInfo> = {
  home: { path: '/home' },
  receipt: { path: '/receipt' },
  ineligibility: { path: '/ineligibility' },
}

const RoutesComponent = () => (
  <React.Suspense fallback={<Shimmer />}>
    <TransitionManager>
      <Switch>
        <Route path="/" component={HomePage} />
        <Route path={MainRoutesInfo.receipt.path} component={ReceiptPage} />
        <Route path={MainRoutesInfo.ineligibility.path} component={Ineligibility} />
      </Switch>
    </TransitionManager>
    <BottomSheet />
  </React.Suspense>
)

export default RoutesComponent
