import { StateCreator } from 'zustand'

import { LoadingState } from './types'

const createLoadingSlice: StateCreator<LoadingState, [], [], LoadingState> = (set) => ({
  loading: { isLoading: false },
  setIsLoading: (isLoading) => set(() => ({ loading: { isLoading } })),
})

export default createLoadingSlice
