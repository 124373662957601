import React from 'react'

import App from 'App'
import { createRoot } from 'react-dom/client'

/*
 * A variável REACT_APP_MSW_DISABLED controla a ativação do MSW.
 * Quando COMENTADA, o MSW estará ATIVO.
 * Caso deseje DESATIVAR o MSW em tempo de desenvolvimento,
 * DESCOMENTE a variável REACT_APP_MSW_DISABLED no .env
 *
 * Será necessário reiniciar o comando `yarn start` para que as alterações
 * tenham efeito.
 * */
if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_MSW_DISABLED !== '1') {
  const { worker } = require('./config/msw/browser')
  worker.start()
}

const rootElement = document.getElementById('root')
if (!rootElement) throw new Error('Failed to find the root element')
const root = createRoot(rootElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
