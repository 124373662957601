export type ErrorState = {
  error: ErrorObject
} & ErrorActions

export interface ErrorObject {
  hasError: boolean
  status?: string | number
  errorResponse: ErrorResponse
  errorInstance: Error
  isDetailedError: boolean
}

export interface ErrorActions {
  setHasError: (hasError: boolean) => void
  setStatus: (status: string | number) => void
  setErrorResponse: (errorResponse: ErrorResponse) => void
  setErrorInstance: (errorInstance: Error) => void
  setIsDetailedError: (isDetailedError: boolean) => void
  resetError: () => void
}

export interface ErrorResponse {
  totalErrors: number
  errors: Array<IError>
  logref?: number
}

export interface IError {
  readonly code: string
  readonly message: string
}

export enum ErrorCodes {
  DEFAULT_ERROR = 'default_error',
  EXPIRED_CONDITION = 'Expired_condition',
}

export enum ErrorMessages {
  DEFAULT_ERROR = 'Infelizmente não foi possível carregar a condição. Tente novamente e confira o que preparamos para você.',
  EXPIRED_CONDITION = 'Infelizmente essa condição não está mais ativa. Acesse a área de Seguros no Super App e confira outras condições especiais para você.',
}

export const INITIAL_STATE: ErrorObject = {
  hasError: false,
  errorResponse: {
    totalErrors: 1,
    errors: [
      {
        code: ErrorCodes.DEFAULT_ERROR,
        message: ErrorMessages.DEFAULT_ERROR,
      },
    ],
  },
  errorInstance: new Error(),
  isDetailedError: false,
}
