import { IWbISafeResponse } from '@interco/inter-webview-bridge'

import { MultiplatformHttpService } from '../../services/multiplatformHttpService/index'
import { MockService } from '../mockService'

/**
 * This service should only be called on browser
 */
class ISafeTokenMockService {
  static async requestISafe(
    category: string,
    checkType: string,
    feature: string,
    value: number,
  ): Promise<IWbISafeResponse> {
    const path = 'app-bridge/i-safe'
    try {
      const response = await MultiplatformHttpService.doExecute<IWbISafeResponse>({
        urlPrefix: MockService.baseUrl,
        endpoint: {
          browser: path,
          bridge: path,
        },
        headers: {
          browser: { 'x-api-key': MockService.apiKey },
        },
        method: 'POST',
        data: {
          category,
          checkType,
          feature,
          value,
        },
      })

      return response.data
    } catch (error: unknown) {
      throw MockService.handleMockServiceErrors(
        error,
        'ISafeTokenMockService.handleMockServiceErrors',
      )
    }
  }
}
export default ISafeTokenMockService
