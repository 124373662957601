import { create } from 'zustand'

import { BottomSheetState } from './bottomSheet/types'
import createErrorSlice from './error'
import { ErrorState } from './error/types'
import createLoadingSlice from './loading'
import { LoadingState } from './loading/types'
import createNavbarSlice from './navbar'
import { NavbarState } from './navbar/types'
import createUserInfoSlice from './user'
import { UserInfoState } from './user/types'
import createBottomSheetSlice from './bottomSheet'
import { PageTrasitionState } from './pageTransitionAnimation/type'
import createPageTransitionSlice from './pageTransitionAnimation'
import createRouterStateSlice from './routerState'
import { RouterState } from './routerState/type'
import { DarkModeState } from './darkMode/types'
import createDarkModeSlice from './darkMode'
import createProductSlice from './product'
import { ProductState } from './product/types'
import createIneligibilitySlice from './ineligibility'
import { IneligibilityState } from './ineligibility/types'
import createViewConfigSlice from './viewConfig'
import { ViewConfigState } from './viewConfig/types'
import { SubmitState } from './receipt/types'
import createSubmitSlice from './receipt'
import createQueryParamsSlice from './queryParams'
import { QueryParamsState } from './queryParams/type'
import { PaymentState } from './chosenPayment/types'
import createPaymentSlice from './chosenPayment'
import createImageBannerSlice from './imageBanner'
import { ImageBannerState } from './imageBanner/type'
import createTypeCampaign from './typeCampaign'
import { TypeCampaignState } from './typeCampaign/type'

type RootState = NavbarState &
  ErrorState &
  LoadingState &
  UserInfoState &
  BottomSheetState &
  PageTrasitionState &
  RouterState &
  DarkModeState &
  ProductState &
  IneligibilityState &
  ViewConfigState &
  SubmitState &
  QueryParamsState &
  PaymentState &
  ImageBannerState &
  TypeCampaignState

const useBoundState = create<RootState>()((...a) => ({
  ...createErrorSlice(...a),
  ...createLoadingSlice(...a),
  ...createNavbarSlice(...a),
  ...createUserInfoSlice(...a),
  ...createBottomSheetSlice(...a),
  ...createPageTransitionSlice(...a),
  ...createRouterStateSlice(...a),
  ...createDarkModeSlice(...a),
  ...createProductSlice(...a),
  ...createIneligibilitySlice(...a),
  ...createViewConfigSlice(...a),
  ...createSubmitSlice(...a),
  ...createQueryParamsSlice(...a),
  ...createPaymentSlice(...a),
  ...createImageBannerSlice(...a),
  ...createTypeCampaign(...a),
}))

export default useBoundState
