import { StateCreator } from 'zustand'

import { INITIAL_STATE, SubmitState } from './types'

const createSubmitSlice: StateCreator<SubmitState, [], [], SubmitState> = (set) => ({
  receipt: INITIAL_STATE,
  setReceipt: (receipt) => set(() => ({ receipt: { receipt } })),
  resetReceipt: () => set(() => ({ receipt: INITIAL_STATE })),
})

export default createSubmitSlice
