import { IWbAppInfo } from '../../services/bridgeService/types'
import { MockService } from '../mockService'
import { MultiplatformHttpService } from '../../services/multiplatformHttpService'

class AppInfoMockService {
  static async requestAppInfo(): Promise<IWbAppInfo> {
    const path = `app-bridge/app-info`
    try {
      const response = await MultiplatformHttpService.doExecute<IWbAppInfo>({
        urlPrefix: MockService.baseUrl,
        endpoint: {
          browser: path,
          bridge: path,
        },
        headers: {
          browser: { 'x-api-key': MockService.apiKey },
        },
        method: 'GET',
      })

      return response.data
    } catch (error: unknown) {
      throw MockService.handleMockServiceErrors(error, 'AppInfoMockService.handleMockServiceErrors')
    }
  }
}

export default AppInfoMockService
