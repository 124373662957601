import React, { useEffect, useRef, useState } from 'react'

import * as S from './styles'
import { IContainerProps } from './types'

type ContainerType = React.FC<IContainerProps & React.HTMLAttributes<HTMLDivElement>>
export const ContainerWithFooter: ContainerType = ({ children, stickyFooter, ...props }) => {
  const [footerHeight, setFooterHeight] = useState(0)
  const footerRef = useRef<HTMLDivElement>(null)
  const childrenRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (footerRef.current) {
      setFooterHeight(footerRef?.current?.clientHeight)
    }
  }, [])

  return (
    <S.Container data-testid="container" {...props}>
      <S.Content data-testid="container-content">
        <div ref={childrenRef} style={{ marginBottom: `${footerHeight}px` }}>
          {children}
        </div>
      </S.Content>

      {stickyFooter && (
        <S.StickyFooter ref={footerRef} data-testid="container-sticky-footer">
          {stickyFooter}
        </S.StickyFooter>
      )}
    </S.Container>
  )
}
