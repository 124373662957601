import { IneligibilityObject } from 'store/ineligibility/types'

export type SubmitState = {
  receipt: SubmitObject
} & SubmitActions

export enum SignalType {
  SIGNAL_ERROR = 'SIGNAL_ERROR',
  SIGNAL_SUCCESS = 'SIGNAL_SUCESS',
  SIGNAL_PENDING = 'SIGNAL_PENDING',
}

export interface Information {
  label: string
  value: string
}

export interface SubmitResponse {
  FeedbackResponse: {
    icon: SignalType
    title: string
    description: string
    planDetails: {
      contractInformation: Information[]
      payment: Information[]
      insuranceCoverage: Information[]
    }
    footer: {
      manual: string
      primaryButton: string
      secondaryButton: string
    }
  }
  ineligibility?: IneligibilityObject
}

export interface SubmitObject {
  receipt: SubmitResponse
}

export interface SubmitActions {
  setReceipt: (receipt: SubmitResponse) => void
  resetReceipt: () => void
}

export const INITIAL_STATE: SubmitObject = {
  receipt: {
    FeedbackResponse: {
      icon: SignalType.SIGNAL_ERROR,
      title: '',
      description: '',
      planDetails: {
        contractInformation: [],
        payment: [],
        insuranceCoverage: [],
      },
      footer: {
        manual: '',
        primaryButton: '',
        secondaryButton: '',
      },
    },
  },
}
