import { PaymentTypes } from 'store/viewConfig/types'

export type PaymentState = {
  payment: PaymentObject
} & PaymentActions

export interface PaymentObject {
  chosenPayment?: PaymentTypes
}

export interface PaymentActions {
  setChosenPayment: (chosenPayment: PaymentTypes) => void
  resetPayment: () => void
}

export const INITIAL_STATE: PaymentObject = {
  chosenPayment: undefined,
}
