import { ReactNode } from 'react'

export type BottomSheetState = {
  bottomSheet: BottomSheetObject
} & BottomSheetActions

export interface BottomSheetObject {
  children: ReactNode | string
  noSwipe: boolean
  fixedHeight: string
  bottomSheetVisible: boolean
}

export interface BottomSheetActions {
  setBottomSheetChildren: (children: ReactNode | string) => void
  setBottomSheetNoSwipe: (noSwipe: boolean) => void
  setBottomSheetVisible: (visible: boolean) => void
  setBottomSheetFixedHeight: (fixedHeight: string) => void
  resetBottomSheet: () => void
}

export const INITIAL_STATE: BottomSheetObject = {
  children: '',
  noSwipe: false,
  fixedHeight: '',
  bottomSheetVisible: false,
}
