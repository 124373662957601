import styled from 'styled-components'

import { Text } from '@interco/inter-ui/components/Text'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 16px 24px 24px;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  height: inherit;
`

export const SignalContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const ErrorTitle = styled(Text)`
  margin: 16px 0 8px;
  font-weight: 600;
`

export const ErrorMessage = styled(Text)`
  margin: 16px 0 16px;
`
