export type PageTrasitionState = {
  pageTrasition: PageTrasitionObject
} & PageTrasitionAction

export enum PageDirections {
  FORWARD = 'forward',
  BACKWARD = 'backward',
}

export interface PageTrasitionObject {
  direction: PageDirections
}

export interface PageTrasitionAction {
  setPageTrasitionDirection: (direction: PageDirections) => void
}

export const INITIAL_STATE: PageTrasitionObject = {
  direction: PageDirections.FORWARD,
}
