import styled from 'styled-components'

export const Container = styled.div`
  background-color: var(--neutral-theme);
  margin: 24px 24px;
`

export const componentShimmer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
`

export const paymentShimmerContent = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 24px;
`
