import { StateCreator } from 'zustand'

import { ImageBannerState, INITIAL_STATE } from './type'

const createImageBannerSlice: StateCreator<ImageBannerState, [], [], ImageBannerState> = (set) => ({
  imageBanner: INITIAL_STATE,
  setImageBanner: (url) => set(() => ({ imageBanner: { imageBannerUrl: url } })),
  resetImageBanner: () => set(() => ({ imageBanner: INITIAL_STATE })),
})

export default createImageBannerSlice
