export type ProductState = {
  product?: ProductObject
} & ProductActions

export interface Plan {
  id: string
  name: string
  value: number
  firstPaymentAfterDays: number
  paymentRecurrence: PaymentRecurenceTypes
}

export interface Documents {
  productManual: string
  termsConditions: string
}

export interface ProductObject {
  id: string
  name?: string
  plans: Plan[]
  documents?: Documents
}

export interface ProductActions {
  setProduct: (product: ProductObject) => void
  resetProduct: () => void
}

export const INITIAL_STATE: ProductObject = {
  id: '',
  name: '',
  plans: [],
  documents: {
    productManual: '',
    termsConditions: '',
  },
}

export enum PaymentRecurenceTypes {
  MONTH = 'MONTH',
  DAY = 'DAY',
}
