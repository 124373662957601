export type ViewConfigState = {
  viewConfig: ViewConfigObject
} & ViewConfigActions

export interface Tag {
  label: string
  theme: string
}

export interface CampaignTitle {
  label: string
  tags?: Tag[]
}

export enum ComponentType {
  LIST_WITH_ICON = 'LIST_WITH_ICON',
  LIST_COVERAGE = 'LIST_COVERAGE',
  TEXT_EDITOR = 'TEXT_EDITOR',
  BANNER = 'BANNER',
}

export interface GenericType {
  type: ComponentType
}

export interface ListWithIcon extends GenericType {
  icon: string
  title: string
  description: string
}

export interface ListCoverage extends GenericType {
  title: string
  value?: string
}

export interface Banner extends GenericType {
  tags?: Tag
  title: string
  subtitle: string
  src?: string
}

export interface ComponentTextEditor extends GenericType {
  value: string
}

export interface Component<T> {
  type: ComponentType
  items: Array<T>
}

export enum PaymentTypes {
  DEBIT = 'DEBIT',
  CREDIT = 'CREDIT',
}

export interface Payment {
  title: string
  payments: {
    allowedTypes: PaymentTypes[]
    default: PaymentTypes
  }
}

export interface ViewConfigObject {
  headerTitle: string
  imageCampaign?: string
  titleCampaign: CampaignTitle
  components: Array<Component<GenericType>>
  paymentMethod: Payment
  submitButton?: {
    label?: string
  }
  bodyComponent?: Component<GenericType>
}

export interface ViewConfigActions {
  setViewConfig: (viewConfig: ViewConfigObject) => void
  resetViewConfig: () => void
}

export const INITIAL_STATE: ViewConfigObject = {
  headerTitle: '',
  imageCampaign: '',
  submitButton: {
    label: '',
  },
  titleCampaign: {
    label: '',
    tags: [],
  },
  components: [],
  paymentMethod: {
    title: '',
    payments: {
      allowedTypes: [],
      default: PaymentTypes.DEBIT,
    },
  },
}
