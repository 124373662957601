import { Skeleton } from '@interco/inter-ui/components/Skeleton'

import { ContainerWithFooter } from '../containerWithFooter'
import * as S from './styles'

const Shimmer = () => {
  const campaignTitleShimmer = () => (
    <div style={{ marginBottom: '24px' }}>
      <Skeleton
        width="69.1667vw"
        height="27px"
        style={{ margin: '0 0 8px 0', borderRadius: '8px', display: 'block' }}
      />
      <Skeleton width="78.0556vw" height="27px" style={{ borderRadius: '8px', display: 'block' }} />
    </div>
  )

  const componentShimmer = () => (
    <>
      {Array.from({ length: 3 }).map((_value, index) => {
        const key = `key-${index.toString()}`
        return (
          <S.componentShimmer key={key} data-testid="component-shimmer">
            <Skeleton
              width="64px"
              height="64px"
              style={{ borderRadius: '8px', display: 'block' }}
            />
            <div style={{ height: '52px' }}>
              <Skeleton
                width="64.4444vw"
                height="16px"
                style={{ margin: '0 0 8px 0', borderRadius: '8px', display: 'block' }}
              />
              <Skeleton
                width="64.4444vw"
                height="12px"
                style={{ margin: '0 0 4px 0', borderRadius: '8px', display: 'block' }}
              />
              <Skeleton
                width="61.1111vw"
                height="12px"
                style={{ margin: '0 0 4px 0', borderRadius: '8px', display: 'block' }}
              />
            </div>
          </S.componentShimmer>
        )
      })}
    </>
  )

  const paymentShimmer = () => (
    <div style={{ marginTop: '32px', marginBottom: '24px' }} data-testid="payment-shimmer">
      <Skeleton
        width="78.89vw "
        height="27px"
        style={{ marginBottom: '8px', borderRadius: '8px', display: 'block' }}
      />
      <Skeleton width="73.61vw" height="14px" style={{ borderRadius: '8px', display: 'block' }} />
      <S.paymentShimmerContent>
        <Skeleton width="41.67vw" height="71px" style={{ borderRadius: '8px', display: 'block' }} />
        <Skeleton width="41.67vw" height="71px" style={{ borderRadius: '8px', display: 'block' }} />
      </S.paymentShimmerContent>
    </div>
  )

  return (
    <ContainerWithFooter data-testid="shimmer">
      <S.Container>
        <Skeleton
          width="100%"
          height="265px"
          style={{ margin: '0 0 24px 0', borderRadius: '8px', display: 'block' }}
        />
        {campaignTitleShimmer()}
        {componentShimmer()}
        {paymentShimmer()}
      </S.Container>
    </ContainerWithFooter>
  )
}

export default Shimmer
